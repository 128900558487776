<template>
  <v-container fluid>
    <section v-if="isError">
      <h1 class="text-center">Terjadi Kesalahan :(</h1>
    </section>
    <section v-else>
      <v-row class="mt-1">
        <v-col>
          <span class="font-weight-bold">
            {{$route.meta.headerTitle}}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              @click="setActiveTab(0)"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyInformation.TAB_NAME}}
            </v-tab>
            <v-tab
              @click="setActiveTab(1)"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyDocuments.TAB_NAME}}
            </v-tab>
            <v-tab
              @click="setActiveTab(2)"
              v-if="$route.params.id"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyRegistration.HISTORY_APPROVAL}}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-card
        elevation="5"
        tile
      >
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyInformation.TAB_NAME}}
            </v-card-title>
            <CompanyInformation
              :form="form"
              @selectTab="setActiveTab"
              :readOnly="readOnly"
              ref="companyInformation"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyDocuments.TAB_NAME}}
            </v-card-title>
            <CompanyDocuments
              :readOnly="readOnly"
              :form="form"
              @submitDocument="submitData"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyRegistration.HISTORY_APPROVAL}}
            </v-card-title>
            <Approval/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </section>
    <!-- Dialog permission logout -->
    <v-dialog
      v-model="dialogLogout"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="d-flex justify-center text-h6">
          Silahkan login kembali !!
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary darken-1"
            @click="logout"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CompanyInformation from '../components/CompanyRegistration/CompanyInformation';
import CompanyDocuments from '../components/CompanyRegistration/CompanyDocuments';
import Approval from '../components/CompanyRegistration/Approval';

export default {
  name: 'CompanyRegistrationPage',
  components: {
    CompanyInformation,
    CompanyDocuments,
    Approval,
  },
  computed: {
    fromPath() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    isApprovalPageInbox() {
      const pageApproval = ['approval-inbox'];
      return pageApproval.includes(this.fromPath);
    },
  },
  data() {
    return {
      dialogLogout: false,
      activeTab: +this.$route.query.tab || 0,
      readOnly: true,
      workflowDetailId: null,
      workflowId: '',
      defWorkflowId: 0,
      form: {
        id: 2,
        name: '',
        abbreviation: '',
        address: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        billingAddress: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        npwpNo: '',
        npwpAddress: '',
        pkpNo: '',
        presidentDirectorSalutation: '',
        presidentDirector: '',
        jobTitle: '',
        siupNo: '',
        tdpNo: '',
        email: '',
        phoneNo: '',
        faxNo: '',
        companyService: '',
        establishDate: '',
        isDraft: false,
        notes: '',
        documentSupports: [],
      },
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    setActiveTab(tab) {
      const _tab = tab || 0;
      this.activeTab = _tab;
      if (+this.$route.query.tab !== _tab) {
        this.$router.replace({
          query: {
            tab: _tab,
          },
        });
      }
    },
    getServiceByDefworkflowId(form) {
      const { id } = this.$route.params;
      const { workflowDetailId, defWorkflowId } = this;
      switch (+defWorkflowId) {
        case 0:
          // jika registrasi pertama kali atau save draf
          return this.$_services.registration.submitCompanyInfo(form);
        case 2:
          // jika revisi registrasi perusahaan
          return this.$_services.registration.reviseCompanyRegistration(
            form,
            id,
            workflowDetailId,
          );
        default:
          break;
      }
    },
    setData(data) {
      const { defWorkflowId } = this.$route.params;
      this.form = data.data;
      if (data.isRevise && this.isApprovalPageInbox) this.readOnly = false;
      this.defWorkflowId = defWorkflowId;
      this.workflowDetailId = data.workflowDetailId;
    },
    async fetchData() {
      const { id, defWorkflowId, data } = this.$route.params;
      if (data) {
        this.setData(data);
      } else if (id && defWorkflowId) {
        await this.fetchWorkflow();
      } else {
        await this.fetchCompanyInformationDraft();
      }
    },
    async fetchWorkflow() {
      try {
        this.$root.$loading.show();
        this.isError = false;
        const { id } = this.$route.params;
        const result = await this.$_services.approval.getWorkflow(id);
        this.setData(result.data);
        this.$root.$loading.hide();
        return result.data;
      } catch (err) {
        this.isError = true;
        this.$root.$loading.hide();
      }
    },
    async fetchCompanyInformationDraft() {
      try {
        this.$root.$loading.show();
        this.readOnly = false;
        const result = await this.$_services.registration.getCompanyInformationDraft();
        const form = result.data;
        if (form.name || form.abbreviation) {
          this.form = form;
        } else {
          this.form.documentSupports = form.documentSupports;
        }
        this.$root.$loading.hide();
        return result.data;
      } catch (err) {
        this.isError = true;
        this.$root.$loading.hide();
      }
    },
    validationDucumentSupport() {
      let valid = true;
      if (this.form.documentSupports.length > 0) {
        this.form.documentSupports.forEach((document) => {
          if (!document.url && document.documentInformation.isMandatory) {
            if (!document.encodedFile) {
              valid = false;
            }
          }
        });
      }
      if (!valid) {
        this.$dialog.notify.error('Mohon upload file yang wajib dilengkapi!!');
      }
      return valid;
    },
    async submitData(isDraft) {
      const validateCompanyInformation = this.$refs.companyInformation?.$refs.form.validate();
      if (!validateCompanyInformation && validateCompanyInformation !== undefined) {
        this.$dialog.notify.error('Mohon lengkapi form yang wajib diisi');
        this.activeTab = 0;
        return;
      }
      const validateDocument = isDraft ? true : this.validationDucumentSupport();
      if (!validateDocument) return;
      const filterDocumentSupports = [];
      this.form.documentSupports.forEach((document) => {
        if (document.encodedFile) {
          filterDocumentSupports.push({
            documentId: document.documentInformation.id,
            documentName: document.fileName,
            size: document.size,
            type: document.type,
            encodedFile: document.encodedFile,
          });
        }
        if (document.url && !document.encodedFile) {
          filterDocumentSupports.push(document);
        }
      });
      this.form.isDraft = isDraft;
      this.form.abbreviation = this.form.abbreviation.toUpperCase();
      const form = {
        ...this.form,
        documentSupports: filterDocumentSupports,
      };
      this.$root.$loading.show();
      try {
        await this.getServiceByDefworkflowId(form);
        if (isDraft) {
          this.$dialog.notify.success('Berhasil menyimpan sebagai draft');
        } else {
          this.$dialog.notify.success('Berhasil mengirim data');
        }
        if (this.workflowDetailId) {
          return this.$router.replace('/main/approval-inbox');
        }
        return this.$router.replace('/main/registration');
      } finally {
        this.$root.$loading.hide();
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  mounted() {
    const { tab } = this.$route.query;
    this.setActiveTab(Number(tab));
    this.fetchData();
  },
};
</script>

<style>
</style>
